
// Medium devices (tablets, 768px and up)

@media (max-width: 767.98px) {

  body
  {
    width: 100%;
    background: rgb(132,21,37);
    background: -moz-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
  }
  
  h1,h2,h3,h4,h5,h6
  {
      margin: 0;
  }
  
  .App {
      text-align: center;
  }
  
  .revb-wrapper 
  {
      background: rgba(132,21,37,0.7);
      background: -moz-linear-gradient(0deg, rgba(132,21,37,.7) 0%, rgba(163,26,45,.7) 100%);
      background: -webkit-linear-gradient(0deg, rgba(132,21,37,.7) 0%, rgba(163,26,45,.7) 100%);
      background: linear-gradient(0deg, rgba(132,21,37,.7) 0%, rgba(163,26,45,.7) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#841525",endColorstr="#a31a2d",GradientType=1);
      min-height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
      width: 100%;
      position: relative;
  
      & > div:nth-child(1)
      {
          width: 100%;
      }
  
      & > div:nth-child(2)
      {
          display: none;
      }
  
      & .revb-cons-img
      {
          width: 100%;
          object-fit: cover;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 9999;
          height:fit-content;
          opacity: 0.04;
      }
  
          & > div
          {
  
          & > div
          {
  
              & > div
              {
  
              & > div 
              {
  
                  & > h1
                  {
                  margin-bottom: .4rem;
                  font-size: 2rem;
                  }
  
              }
  
              & > article
              { 
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  line-height: 90%;
                  margin-bottom: 2rem;
  
                  & > p
                  {
                    text-align: center;
                    font-size: .9rem;
                    width: 60%;
                    font-weight: 300;
                    display: none;
                  }
  
              }
  
              }
  
          }
  
          }
  
      & .revb-counter
      {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          align-content: center;
          flex-wrap: wrap;
          width: 100%;
          margin: 0 auto;
  
          & > div
          {
          background-color: #fff;
          margin: .5rem .5rem;
          width: 7rem;
          height: 7rem;
          color: #A11A2C;
          border-radius: .8rem;
          
          & > div
          {
              position: relative;
          }
          
          & > div:nth-child(1)
          {
              height: 70%;
              align-items: center;
              display: flex;
              text-align: center;
              justify-content: center;
          }
          }
      }
  }

  .revb-container-logo
  {

    margin-bottom: 2rem;
    & > img
    {
        width: 15rem;
    }

  }

  .revb-dots
  {
    position: absolute;
    right: 0%;
    bottom: 0rem;
    opacity: .2;
    
  }



  .revb-contact
  {
    margin-top: 3rem;

    & > div
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: .5rem .5rem;

        & > div
        {

        &:hover
        {
            background-color: #fff;
            border-radius: .2rem;

            & > a
            {
              color: #A11A2C;
              text-decoration: none;
              font-size: 1.4rem;
              font-weight: 300;
              margin: 0rem .2rem;
              display: flex;
              align-items: center;
              padding: 5px 1rem;
              font-size: .9rem;
              padding-right: 1.4rem;
    
              & > img 
              {
                  width: 28px;
                  padding: 0px .4rem;
                  color:#A11A2C;
                  

                  & > svg
                  {
                  color:#A11A2C;
                  }
              }
            }


        }

        & > a
        {
            color: #fff;
            text-decoration: none;
            font-size: 1.4rem;
            font-weight: 300;
            margin: 0rem 0rem;
            display: flex;
            align-items: center;
            padding: 5px .1rem;
            font-size: .9rem;
            padding-right: 1rem;
            transition: color 0.30s ease-in-out, background-color 0.30s ease-in-out, border-color 0.30s ease-in-out, box-shadow 0.15s ease-in-out;

            & > span
            {
              display: none;
            }

            & > i 
            {
            width: 28px;
            padding: 0px .4rem;
            }
        }
      }
    }
  }



}


