
// Large devices (desktops, 992px and up)
@media (max-width: 991.98px) {

  body
  {
    width: 100%;
    background: rgb(132,21,37);
    background: -moz-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
  }

  h1,h2,h3,h4,h5,h6
  {
    margin: 0;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
  }

  .revb-wrapper 
  {
    background: rgb(132,21,37);
    background: -moz-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    background: linear-gradient(0deg, rgba(132,21,37,1) 0%, rgba(163,26,45,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#841525",endColorstr="#a31a2d",GradientType=1);
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;

    & > div:nth-child(1)
    {
        width: 70%;
    }

    & > div:nth-child(2)
    {
        width: 30%;
        display: flex;
        height: 100vh;
    }

    & .revb-cons-img
    {
        width: 100%;
        object-fit: cover;
    }

        & > div
        {

        & > div
        {

            & > div
            {

            & > div 
            {

                & > h1
                {
                margin-bottom: .4rem;
                font-size: 2.5rem;
                }

            }

            & > article 
            { 
                width: 100%;
                display: flex;
                justify-content: center;
                line-height: 90%;
                margin-bottom: 2rem;

                & > p
                {
                  display: none;
                  text-align: center;
                  font-size: .9rem;
                  width: 60%;
                  font-weight: 300;
                }

            }

            }

        }

        }

    & .revb-counter
    {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      align-content: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 60%;
      margin: 0 auto;

      & > div
      {
          background-color: #fff;
          margin: 1rem .2rem;
          width: 8rem;
          height: 8rem;
          color: #A11A2C;
          border-radius: .8rem;
        
        & > div
        {
            position: relative;
        }
        
        & > div:nth-child(1)
        {
            height: 70%;
            align-items: center;
            display: flex;
            text-align: center;
            justify-content: center;
        }
      }
    }
  }

  .revb-circle
  {

  opacity: .1;
  position: absolute;
  left: calc(-90px + 2vmin);

  & > div
  {  
      width: 10rem;
      height: 10rem;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div
      {
      width: 6rem;
      height: 6rem;
      background-color: rgb(132,21,37);
      border-radius: 50%;
      }

  }

  }

  .revb-dots
  {
    position: absolute;
    right: 20%;
    bottom: 1rem;
  }

  .revb-container-logo
  {

  margin-bottom: 4rem;
  & > img
  {
      width: 22rem;
  }

  }


  .revb-contact
  {
  margin-top: 4rem;

  & > div
  {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: .5rem .5rem;

      & > div
      {

      &:hover
      {
          background-color: #fff;
          border-radius: .2rem;


          & > a
          {
          color: #A11A2C;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: 300;
          margin: 0rem .2rem;
          display: flex;
          align-items: center;
          padding: 5px 1rem;
          font-size: .9rem;
          padding-right: 1.4rem;
  
          & > img 
          {
              width: 28px;
              padding: 0px .4rem;
              color:#A11A2C;
              

              & > svg
              {
              color:#A11A2C;
              }
          }
          }


      }

      & > a
      {
          color: #fff;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: 300;
          margin: 0rem .2rem;
          display: flex;
          align-items: center;
          padding: 5px 1rem;
          font-size: .9rem;
          padding-right: 1.4rem;
          transition: color 0.30s ease-in-out, background-color 0.30s ease-in-out, border-color 0.30s ease-in-out, box-shadow 0.15s ease-in-out;

          & > i 
          {
          width: 28px;
          padding: 0px .4rem;
          }
      }
      }
      
  }
  }


  .revb-intro-wrapper
  {
  background: #fff;
  width: 100%;
  height: 100vh;

  & > article
  {
      display: flex;
      justify-content: center;
      align-self: center;
      height: 100%;

      & > div
      {
      display: flex;
      align-items: center;
      }
  }
  }

  /* .revb-fade
  {
  opacity: 0;
  } */

  @keyframes in-fade {
  0%   { opacity: 0; }
  100% { opacity: 1; }
  }


  .revb-fade{
  animation-name: in-fade;
  animation-delay: 0s;
  animation-duration: 1000ms;
  }

}
